<template>
  <NavBar />
  <router-view />
  <div class="container">
    <div class="Porfolio__home container__headline" id="section">
      <div class="Porfolio__flexbox">
        <div class="img__peacontainer">
          <img alt="Vue" class="img_pea" src="./assets/mh.webp" />
        </div>

        <!-- <video
          id="video"
          class="video"
          preload="metadata"
          autoplay
          muted
          loop
          playsinline
        >
          <source class="img_pea" src="./assets/math.mp4" type="video/mp4" />
          <source class="img_pea" src="./assets/mathi.mp4" type="video/mp4" />
          <source class="img_pea" src="./assets/math.webm" type="video/webm" />
          <source src="images/math.m4v" type="video/x-m4v" />

          Your browser does not support the video tag.
        </video> -->

        <div class="Portfolio__introduction__box">
          <h1 data-anim data-add-class="translate-in" data-delay="0.45">
            {{ $t("main.title") }}
          </h1>
          <p data-anim data-add-class="translate-in--max" data-delay="0.45">
            {{ $t("main.subtitle") }}
          </p>
          <p
            data-anim
            data-add-class="translate-in--max"
            class="text"
            data-delay="0.75"
          >
            {{ $t("main.text") }}
          </p>
          <button class="btn_download" data-cursor="hover">
            <a href="/mathieuhollandeCV2025.pdf" download="cv"
              >{{ $t("main.resume") }} ⬇
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
  <section class="hero">
    <div class="hero__inner">
      <div class="hero__images">
        <div class="hero__content">
          <div class="hero__headline">
            <span>{{ $t("main.meet") }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="Porfolio__container">
    <section>
      <div class="marquee">
        <ul class="marquee__content">
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
        </ul>
        <ul aria-hidden="true" class="marquee__content bottom">
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
        </ul>
      </div>
    </section>
    <!--  <div class="Porfolio__home">
      <div>
             <p>Search projects</p>
 
      </div>
    </div> -->

    <div class="Porfolio__project" id="sectiontwo">
      <swiper
        slides-per-view="3"
        :space-between="20"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :loop="true"
        :autoplay="{ delay: 2500 }"
        :breakpoints="{
          0: {
            slidesPerView: 1, // 1 slide for screens 0px and above
          },
          501: {
            slidesPerView: 3, // 3 slides for screens wider than 500px
          },
        }"
      >
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.quote") }}</h3>
            <p class="active">
              {{ $t("main.quotetext") }}
            </p>
            <button class="btn_download on">
              <a href="https://codepen.io/mateusc59/pen/jOpVvzN">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/quote.png"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.client1") }}</h3>
            <p class="active">
              {{ $t("main.client1text") }}
            </p>
            <button class="btn_download on">
              <a href="https://passelinhypotheque.com/" target="_blank">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/client1.webp"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.client2") }}</h3>
            <p class="active">
              {{ $t("main.client2text") }}
            </p>
            <button class="btn_download on">
              <a href="https://acamas.ca/equipe/" target="_blank">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/client2.webp"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.client3") }}</h3>
            <p class="active">
              {{ $t("main.client3text") }}
            </p>
            <button class="btn_download on">
              <a href="https://gravelgp.com/" target="_blank">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/client3.webp"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.client4") }}</h3>
            <p class="active">
              {{ $t("main.client4text") }}
            </p>
            <button class="btn_download on">
              <a href="https://ateliergauthier.ca/" target="_blank">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/client4.webp"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.client5") }}</h3>
            <p class="active">
              {{ $t("main.client5text") }}
            </p>
            <button class="btn_download on">
              <a href="https://iaqcrivesud.ca/" target="_blank">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/client5.webp"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.multi") }}</h3>
            <p class="active">
              {{ $t("main.multitext") }}
            </p>
            <button class="btn_download on">
              <a href="https://codepen.io/mateusc59/pen/LYBWdGM" target="_blank"
                >{{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/multistep.png"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.tips") }}</h3>
            <p class="active">
              {{ $t("main.tipstext") }}
            </p>
            <button class="btn_download on">
              <a href="https://codepen.io/mateusc59/pen/qBKwRRV">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img alt="Vue" class="Porfolio__card_img" src="./assets/tips.png" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.test") }}</h3>
            <p class="active">
              {{ $t("main.testtext") }}
            </p>
            <button class="btn_download on">
              <a href="https://mytrainingwebsite.vercel.app/" target="_blank">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img alt="Vue" class="Porfolio__card_img" src="./assets/test.png" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.review") }}</h3>
            <p class="active">
              {{ $t("main.reviewtext") }}
            </p>
            <button class="btn_download on">
              <a href="https://codepen.io/mateusc59/pen/vYrMymm">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/review.png"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.scan") }}</h3>
            <p class="active">
              {{ $t("main.scantext") }}
            </p>
            <button class="btn_download on">
              <a href="https://codepen.io/mateusc59/pen/PoaVyRx">
                {{ $t("main.moreinfo") }}
              </a>
            </button>
            <img alt="Vue" class="Porfolio__card_img" src="./assets/scan.png" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="Porfolio__card">
            <h3>{{ $t("main.more") }}</h3>
            <p class="active">
              {{ $t("main.moretext") }}
              <!--               <a href="https://codepen.io/mateusc59/pen/PoaVyRx">More info</a>
 -->
            </p>
            <img
              alt="Vue"
              class="Porfolio__card_img"
              src="./assets/code1.png"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <section>
      <div class="marquee marquee--reverse">
        <ul class="marquee__content">
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
        </ul>

        <ul aria-hidden="true" class="marquee__content">
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
          <li>
            <h2>{{ $t("main.projects") }}</h2>
          </li>
        </ul>
      </div>
    </section>
  </div>
  <div class="container second">
    <h2
      class="container__headline"
      data-anim
      data-add-class="translate-in"
      data-delay="0.75"
    >
      {{ $t("main.neat") }}.
    </h2>
  </div>
  <footer>
    <div class="Portfolio__border"></div>
    <h2>{{ $t("main.follow") }}</h2>
    <div class="Portfolio__icon">
      <a
        data-cursor="hover"
        href="https://www.instagram.com/hldemth/"
        target="_blank"
      >
        <i class="fa-brands fa-instagram"></i
      ></a>
      <a data-cursor="hover" href="https://github.com/Mateusc59" target="_blank"
        ><i class="fa-brands fa-github"></i
      ></a>
      <a
        data-cursor="hover"
        href="http://www.linkedin.com/in/mathieu-hollande"
        target="_blank"
        ><i class="fa-brands fa-linkedin"></i
      ></a>
    </div>
    <p id="sectionthree">
      {{ $t("main.credits") }}
    </p>
  </footer>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import "./web-components/custom-cursor/custom-cursor";
import "./swiper-fullscreen/swiper-fullscreen";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper/core";

// Import Swiper styles
import "swiper/css";

SwiperCore.use([Autoplay]);

export default {
  name: "App",
  components: {
    NavBar,
    Swiper,
    SwiperSlide,
  },

  setup() {
    const onSwiper = () => {
      /*       console.log(swiper);
       */
    };
    const onSlideChange = () => {
      /*       console.log("slide change");
       */
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>

<style lang="scss">
@import "main.scss";
</style>
