export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principale"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Portfolio"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me joindre"])}
  },
  "main": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut, je suis Mathieu"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développeur frontend"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passionné par le développement web et animé par un esprit curieux, je combine créativité et technicité pour donner vie à des projets digitaux sur mesure. Fort de mon expérience avec Vue.js, WordPress, Elementor Pro, Nuxt.js et CSS, je transforme des maquettes Figma en sites web dynamiques et intuitifs. Toujours prêt à relever de nouveaux défis, je cherche aujourd’hui à m’investir dans un métier qui allie action, aventure et impact."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum Vitae"])},
    "meet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enchanté de vous rencontrer"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MES PROJETS"])},
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generateur de quote"])},
    "quotetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici un petit projet utilisant Javascript et API où vous pourrez avoir une citation aléatoire si vous en ressentez le besoin..."])},
    "client1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courtier Hypothecaire"])},
    "client1text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refonte d’un site web pour courtier immobilier réalisée sur WordPress avec Elementor. Design 100 % personnalisé pour mettre en valeur les biens et offrir une expérience utilisateur fluide et professionnelle."])},
    "client2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acamas.ca"])},
    "client2text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d’un site web sur WordPress avec Elementor pour une agence immobilière. Design sur mesure pensé pour valoriser les annonces et faciliter la navigation des utilisateurs."])},
    "client3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravelgp.com"])},
    "client3text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d’un site web pour une entreprise de sécurité financière, développé avec WordPress, CSS, JavaScript et des animations fluides grâce à GSAP"])},
    "client4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ateliergauthier.ca"])},
    "client4text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site pour un artisan en métal forgé créé avec WordPress, CSS, JavaScript en Français et Anglais. Design sur mesure mettant en valeur le savoir-faire artisanal."])},
    "client5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iaqcrivesud.ca"])},
    "client5text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site pour une agence financière développé avec WordPress, CSS, JavaScript"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire à plusieurs étapes"])},
    "multitext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour celui-ci, j'ai utilisé principalement Javascript et Css pour créer un formulaire à plusieurs étapes où vous pouvez ajouter votre abonnement."])},
    "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["    Tips Calcultator    "])},
    "tipstext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["    J'essaie toujours de trouver le juste prix à offrir à quelqu'un, alors si vous avez besoin de calculer et de partager l'addition, voici un calculateur d'astuces JS."])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revue"])},
    "reviewtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis toujours en train d'entraîner mes compétences en Js avec ce commentaire de soumission juste pour donner un grand cinq à quelqu'un."])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balayage"])},
    "scantext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psst.... si vous avez besoin de plus d'informations, vous pouvez utiliser votre téléphone et scanner ici, un projet assez simple mais ne manquez pas l'occasion de vous connecter."])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
    "testtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un site web frontal utilisant HTML/CSS/JS, c'est une page simple, c'était ma première tentative de recréer une page d'atterrissage complète"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de détails à venir"])},
    "moretext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai travaillé sur un gros projet pendant mon séjour dans ma précédente agence, c'était génial, j'ai hâte de continuer "])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus "])},
    "neat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voilà"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-moi"])},
    "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022. Vue.js & CSS Portfolio. Développé par Mathieu Hollande"])}
  }
}